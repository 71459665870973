import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "error-msg"
}
const _hoisted_2 = {
  key: 1,
  id: "app"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return ($setup.isMobile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("h1", null, "Sorry, this site is not ready for mobile devices. Please visit on your computer!", -1)
      ])))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("nav", null, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Home")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/academics" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Academics")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/projects" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Projects")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/work-experience" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Work Experience")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/contact" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Contact")
            ])),
            _: 1
          })
        ]),
        _createVNode(_component_router_view)
      ]))
}