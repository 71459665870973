import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "contact-content" }, [
      _createElementVNode("h1", null, "Contact"),
      _createElementVNode("p", null, "Feel free to reach out to me at:"),
      _createElementVNode("p", null, "harizvi1414 AT ucla DOT edu for any inquiries! "),
      _createElementVNode("p", null, [
        _createTextVNode(" You can also find me on "),
        _createElementVNode("a", {
          href: "https://www.linkedin.com/in/hassan-rizvi-01a804214/",
          target: "_blank",
          rel: "noopener noreferrer"
        }, "LinkedIn"),
        _createTextVNode(" and "),
        _createElementVNode("a", {
          href: "https://github.com/rizvi-ha",
          target: "_blank"
        }, "Github")
      ])
    ], -1)
  ])))
}