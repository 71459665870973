import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-content" }, [
      _createElementVNode("h1", null, "Hassan Rizvi"),
      _createElementVNode("p", null, "I'm a currently a Computer Science student at UCLA (with some courses in Mechanical Engineering as well)"),
      _createElementVNode("p", null, "My professional interests include software engineering, developer tooling, CI/CD, machine learning, embedded software, and more!"),
      _createElementVNode("p", null, "My personal interests include studying Mandarin, reading, gardening, building mechanical keyboards, and exploring LA's restaurant scene (and cats ;P)")
    ], -1)),
    _createElementVNode("img", {
      src: require('@/assets/cat.jpg'),
      alt: "Portfolio Image",
      class: "portfolio-image"
    }, null, 8, _hoisted_2)
  ]))
}