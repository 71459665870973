import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "projects" }
const _hoisted_3 = {
  key: 0,
  class: "project-list"
}
const _hoisted_4 = { class: "project-info" }
const _hoisted_5 = { class: "image-carousel" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 1,
  class: "project-list"
}
const _hoisted_10 = { class: "project-info" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "image-carousel" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["src"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title-container" }, [
      _createElementVNode("h1", null, "Projects")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: _normalizeClass(["tab", { active: _ctx.activeTab === 'forFun' }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 'forFun'))
      }, " Fun ", 2),
      _createElementVNode("button", {
        class: _normalizeClass(["tab", { active: _ctx.activeTab === 'technical' }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 'technical'))
      }, " Technical ", 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.activeTab === 'forFun')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forFunProjects, (project, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "project-entry"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h3", null, _toDisplayString(project.title), 1),
                  _createElementVNode("p", null, _toDisplayString(project.description), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.prevImage(index)),
                    class: "carousel-button"
                  }, "‹", 8, _hoisted_6),
                  _createElementVNode("img", {
                    src: project.images[_ctx.carouselIndices[_ctx.activeTab][index]],
                    alt: "Project Image",
                    class: "project-image"
                  }, null, 8, _hoisted_7),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.nextImage(index)),
                    class: "carousel-button"
                  }, "›", 8, _hoisted_8)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeTab === 'technical')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.technicalProjects, (project, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "project-entry"
              }, [
                _createElementVNode("div", _hoisted_10, [
                  (project.link == '#')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("h3", null, _toDisplayString(project.title), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("a", {
                          href: project.link,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }, [
                          _createElementVNode("h3", null, _toDisplayString(project.title), 1)
                        ], 8, _hoisted_13)
                      ])),
                  _createElementVNode("p", null, _toDisplayString(project.description), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  (project.images.length > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.prevImage(index)),
                          class: "carousel-button"
                        }, "‹", 8, _hoisted_16)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("img", {
                    src: project.images[_ctx.carouselIndices[_ctx.activeTab][index]],
                    alt: "Project Image",
                    class: "project-image"
                  }, null, 8, _hoisted_17),
                  (project.images.length > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.nextImage(index)),
                          class: "carousel-button"
                        }, "›", 8, _hoisted_19)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}