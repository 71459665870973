import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "academics-container" }
const _hoisted_2 = { class: "tabs" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "list-entry" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("h1", null, "Academics", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass({ active: $data.activeTab === 'research' }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.activeTab = 'research'))
      }, "Research", 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: $data.activeTab === 'classes' }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.activeTab = 'classes'))
      }, "Favorite Courses", 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: $data.activeTab === 'extracurriculars' }),
        onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.activeTab = 'extracurriculars'))
      }, "Extracurriculars", 2)
    ]),
    _createVNode(_Transition, {
      name: "slide-fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("div", {
          key: $data.activeTab,
          class: "tab-content"
        }, [
          ($data.activeTab === 'research')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[3] || (_cache[3] = _createElementVNode("h2", null, "UCLA Brain Tumor Imaging Laboratory", -1)),
                  _cache[4] || (_cache[4] = _createElementVNode("p", null, "During the school year, I do computer vision and deep learning research on MRI images with UCLA Health's Brain Tumor Imaging Laboratory.", -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("p", null, "This involves running experiments using the PyTorch and MONAI frameworks. For example, one of my projects is to generate PET scans from MRI images using a Generative Adversarial Network. (which would be cool because getting PET scans is expensive, invasive, and radioactive)", -1)),
                  _createElementVNode("img", {
                    src: require('@/assets/ddd.png'),
                    alt: "BTIL Image",
                    class: "btil-image"
                  }, null, 8, _hoisted_5)
                ])
              ]))
            : _createCommentVNode("", true),
          ($data.activeTab === 'classes')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[6] || (_cache[6] = [
                _createElementVNode("div", { class: "list-entry" }, [
                  _createElementVNode("h2", null, "CS 131 - Programming Languages"),
                  _createElementVNode("h3", null, "Professor: Paul Eggert"),
                  _createElementVNode("p", null, "Concepts in design and use of programming languages, including abstraction, modularity, control mechanisms, types, declarations, syntax, and semantics. Study of several different language paradigms, including functional, object-oriented, and logic programming."),
                  _createElementVNode("p", null, "I particularly enjoyed our deep dive into functional programming, including implementing grammar parsers in OCaml.")
                ], -1),
                _createElementVNode("div", { class: "list-entry" }, [
                  _createElementVNode("h2", null, "CS 188 - Deep Learning for Computer Vision"),
                  _createElementVNode("h3", null, "Professor: Bolei Zhou"),
                  _createElementVNode("p", null, " Study of deep learning approaches for computer vision. Students learn to implement and tune the deep neural networks used in various computer vision, such as visual recognition and image generation. Covers learning algorithms, neural architecture design, and practical skills of training and debugging neural networks."),
                  _createElementVNode("p", null, [
                    _createTextVNode("For extra credit, a classmate and I made a functional "),
                    _createElementVNode("a", { href: "https://github.com/rizvi-ha/pconv-demo" }, "web demo"),
                    _createTextVNode(" to show off partial convolutions for image inpainting (filling in holes in images using AI).")
                  ])
                ], -1),
                _createElementVNode("div", { class: "list-entry" }, [
                  _createElementVNode("h2", null, "CS 111 - Operating Systems"),
                  _createElementVNode("h3", null, "Professor: Peter Reiher"),
                  _createElementVNode("p", null, "Principles and design of operating systems. Topics include process management, interprocess communication, memory management, file systems, and device drivers. Basically you learn how your computer *actually* works, which I found fascinating."),
                  _createElementVNode("p", null, "I found the concept of paging and virtual memory really interesting, and it was in general just cool to see what goes on in the middle between hardware and application-level software.")
                ], -1),
                _createElementVNode("p", null, null, -1)
              ])))
            : _createCommentVNode("", true),
          ($data.activeTab === 'extracurriculars')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[7] || (_cache[7] = [
                _createElementVNode("div", { class: "list-entry" }, [
                  _createElementVNode("h2", null, "3D4E @ UCLA (3D Printing for Everyone)"),
                  _createElementVNode("p", null, "○ 1st year: Flight team member (worked on plane)"),
                  _createElementVNode("p", null, "○ 2nd year: Flight team member, Secretary"),
                  _createElementVNode("p", null, "○ 3rd year: Biomed Project Lead"),
                  _createElementVNode("p", null, "○ 4th year: Mechanical Keyboards Project Lead"),
                  _createElementVNode("p", null, " I have been with 3D4E since my first year at UCLA, and it has been a rewarding leadership experience, as well as a great avenue for creativity and tinkering! See my project page for some of the things I've built with the club ")
                ], -1),
                _createElementVNode("div", { class: "list-entry" }, [
                  _createElementVNode("h2", null, " UCLA Mildred E. Mathias Botanical Garden "),
                  _createElementVNode("p", null, " On quarters where my schedule allows, I volunteer at the Botanical Garden and assist with planting, weeding, mulching, and other general maintenance! (If you ever visit UCLA you've got to check out the Botanical Garden) ")
                ], -1)
              ])))
            : _createCommentVNode("", true)
        ]))
      ]),
      _: 1
    })
  ]))
}