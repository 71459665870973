import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "work-container" }
const _hoisted_2 = { class: "work-entry" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "work-entry" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Work Experience", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "entry-content" }, [
        _createElementVNode("h2", null, [
          _createTextVNode("Ultima Genomics, "),
          _createElementVNode("i", null, "Software Engineering Intern")
        ]),
        _createElementVNode("p", null, "Fremont, CA | June 2024 - September 2024"),
        _createElementVNode("p", null, "○ Developed and integrated a thorough testing suite for an alerts API and internal dashboard, implementing 140+ unit and integration tests, ensuring reliability through automatic GitHub CI/CD hooks, reducing deployment errors by 100%."),
        _createElementVNode("p", null, "○ Designed a dynamic alert muting management system, utilizing a cloud-based PostgresSQL database to store mute cases, enabling seamless updates through a web interface, increasing the visibility and access of important information to engineers."),
        _createElementVNode("p", null, "○ Refactored core components of a data visualization platform, increasing code maintainability, leading to more efficient development, future scalability and long-term reliability.")
      ], -1)),
      _createElementVNode("img", {
        src: require('@/assets/ugenomics.png'),
        alt: "Ultima genomics image",
        class: "resume-image"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "entry-content" }, [
        _createElementVNode("h2", null, [
          _createTextVNode("SpaceX, "),
          _createElementVNode("i", null, "Recovery Engineering Intern")
        ]),
        _createElementVNode("p", null, "Cape Canaveral, FL | June 2023 - September 2023"),
        _createElementVNode("p", null, "○ Developed Python software to optimize Droneship ballast fill levels based on current ship configuration and ullage for 10% faster turnaround between rocket landings."),
        _createElementVNode("p", null, "○ Designed and implemented a pneumatic Remote Day Shapes system that remotely changes naval signals to allow for autonomous Droneship missions off-shore, clearing a regulatory hurdle within a limited timeframe."),
        _createElementVNode("p", null, "○ Planned and programmed PLC-powered test bench for rocket-catching Octagrabber robot, writing software in TwinCAT3 with modular functionality to more easily pinpoint faulty parts and identify root cause of failure modes.")
      ], -1)),
      _createElementVNode("img", {
        src: require('@/assets/spacex.jpg'),
        alt: "SpaceX image",
        class: "resume-image"
      }, null, 8, _hoisted_5)
    ])
  ]))
}